let teamCard = [...document.getElementsByClassName('teammate-card')];
let teammatePopup = document.getElementById('teammate-popup');
if(teamCard.length && teammatePopup){
    let scrollContainer = teammatePopup.querySelector('.teammate-popup-block-inner');
    let scrollSelector = teammatePopup.querySelector('.simplebar-content-wrapper');
    window.showTeammatePopup = function showTeammatePopup(card){
        let thumbnail =  card.querySelector('.teammate-card-thumbnail').innerHTML;
        let info = card.querySelector('.teammate-popup-info').innerHTML;

        let popupThumbnail = teammatePopup.querySelector('.teammate-popup-thumbnail');
        let popupInfo = teammatePopup.querySelector('.teammate-popup-text');
        popupThumbnail.innerHTML = thumbnail;
        popupInfo.innerHTML = info;
        $(teammatePopup).fadeIn();
        $(teammatePopup).addClass('active');
        scrollLock.disablePageScroll(scrollSelector);
        scrollLock.addScrollableSelector('#teammate-popup .simplebar-content-wrapper');
    }
    window.closeTeammatePopup = function closeTeammatePopup(card){
        $(teammatePopup).removeClass('active');
        $(teammatePopup).fadeOut('active');
        scrollLock.enablePageScroll()
    }
}