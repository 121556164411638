// Billing calculator
const billingInputWrap = document?.querySelector('.calculator-input__billing'),
      billingInput = billingInputWrap?.querySelector('input'),
      billingResultNum = document?.querySelector('.calculator-billing__result');

// Referral Calculator
const referralInputs = document.querySelectorAll('.calculator-referral__input input'),
      referralResultNum = document.querySelector('.calculator-referral__result');

// Total
const totalResultNum = document.querySelector('.calculator-total-result__num');

let billingResult = 0;
let referralResult = 0;
let referralsCount = 0;
let referralValue = 0;

let symbol = billingInput.getAttribute('data-symbol') || "$";

function stripSymbol(value, symbol) {
    if (value.startsWith(symbol)) {
      return value.slice(symbol.length);
    }
    return value;
}

// Функція для форматування чисел з комами
function formatNumberWithCommas(number) {
  return number.toLocaleString('en-US', { maximumFractionDigits: 0 });
}

// Функція для видалення всіх нецифрових символів, крім крапки
function stripNonNumeric(value) {
  return value.replace(/[^0-9]/g, '');
}

function calcBillingCalculator(e) {
  let inputValue = e.target.value;

  // Видаляємо символ, якщо він вже є на початку
  inputValue = stripSymbol(inputValue, symbol);

  // Видаляємо всі нецифрові символи
  inputValue = stripNonNumeric(inputValue);

  if (inputValue === "") {
    billingInput.value = "";
    billingResultNum.innerHTML = `${symbol}0`;
    billingResult = 0;
    calcTotal();
    return;
  }

  // Додаємо символ на початок та форматуючи число з комами
  e.target.value = `${symbol}${formatNumberWithCommas(parseInt(inputValue))}`;

  // Видаляємо символ для розрахунків
  const numericValue = parseFloat(inputValue);

  if (!isNaN(numericValue)) {
    billingResult = Math.round(numericValue * 0.7);
    billingResultNum.innerHTML = `${symbol}${formatNumberWithCommas(billingResult)}`;
  } else {
    billingResultNum.innerHTML = `${symbol}0`;
  }
  calcTotal();
}

function calcReferralCalculator(e, input) {
  let inputValue = e.target.value;
  let numericValue = 0; // Ініціалізація numericValue

  if(input.getAttribute('data-name') === 'referrals-count') {
    inputValue = stripNonNumeric(inputValue);

    if (inputValue === "") {
      input.value = "";
      referralsCount = 0;
      referralResult = 0; // Оновлюємо referralResult до 0
      referralResultNum.innerHTML = `${symbol}0`; // Оновлюємо результат на екрані
      calcTotal();
      return;
    }

    e.target.value = formatNumberWithCommas(parseInt(inputValue));
    referralsCount = parseInt(inputValue);
  } else if (input.getAttribute('data-name') === 'referral-value') {
    // Видаляємо символ, якщо він вже є на початку
    inputValue = stripSymbol(inputValue, symbol);

    // Видаляємо всі нецифрові символи
    inputValue = stripNonNumeric(inputValue);

    if (inputValue === "") {
      input.value = "";
      referralValue = 0;
      referralResult = 0; // Оновлюємо referralResult до 0
      referralResultNum.innerHTML = `${symbol}0`; // Оновлюємо результат на екрані
      calcTotal();
      return;
    }

    // Додаємо символ на початок та форматуючи число з комами
    e.target.value = `${symbol}${formatNumberWithCommas(parseInt(inputValue))}`;

    numericValue = parseFloat(inputValue);

    if (!isNaN(numericValue)) {
      referralValue = numericValue;
    }
  }

  referralResult = Math.round((referralsCount * referralValue) * 0.05);

  if (!isNaN(referralResult)) {
    referralResultNum.innerHTML = `${symbol}${formatNumberWithCommas(referralResult)}`;
  } else {
    referralResultNum.innerHTML = `${symbol}0`;
  }
  calcTotal();
}

function calcTotal() {
  let totalValue = billingResult + referralResult;
  totalResultNum.innerHTML = `${symbol}${formatNumberWithCommas(totalValue)}`;
}

billingInput.addEventListener('input', (e) => calcBillingCalculator(e));

referralInputs.forEach((input) => {
  input.addEventListener('input', (e) => calcReferralCalculator(e, input));
});
