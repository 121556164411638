$(document).ready(function(){
  let quizButtons = document.querySelectorAll('.quiz-list-item-title');
  if(quizButtons){

    quizButtons.forEach((button, idx)=>{
      let currentIdx = idx;
      button.addEventListener('click', function(){
        button.classList.toggle('active');
        quizButtons.forEach((button,idx)=>{currentIdx !== idx ? button.classList.remove('active'): ''})
      })
    })
  }
})

