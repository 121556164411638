var popupFD = document.querySelector('#popup');

if (popupFD) {
  /*var popupTitle = popupFD.querySelector(".popup__title");*/
  var popupBlock = popupFD.querySelector('#popup-block');

  window.pushPopup = function pushPopup(parentBlock, classHelper = '') {
    if (classHelper) {
      const cls = classHelper.split(' ');
      popupBlock.classList.add(...cls);
    }

    popupBlock.innerHTML = '';
    popupBlock.innerHTML = document.querySelector(parentBlock).innerHTML;

    popupFD.classList.add('active');
    scrollLock.disablePageScroll(document.querySelector('.navbar-nav .menu'));
    scrollLock.addScrollableSelector('.simplebar-content-wrapper');

    var scrollContentElement = document.querySelector('.popup__content');

    if (scrollContentElement) {
      var simpleBarInstance = new SimpleBar(scrollContentElement);
      simpleBarInstance.recalculate();
    }
  };
}

window.closePopup = function closePopup() {
  document.querySelector('#popup').classList.remove('active');
  scrollLock.clearQueueScrollLocks();
  scrollLock.enablePageScroll();
};