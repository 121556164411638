import {CountUp} from 'countup.js';

/**
 * CountUp Metamorfosi function
 * (just add .count-up class anywhere and setup unique option in data attributes)
 * **/
/**
 * CountUp Metamorfosi function
 * (just add .count-up class anywhere and setup unique option in data attributes)
 * **/
window.countUp = function countUp(element = 'count-up', idSuffix = 'count-up') {
    const counts = Array.from(document.getElementsByClassName(element));
    if (counts) {
        const defaultOptions = {
            separator: '',
            enableScrollSpy: false,
            scrollSpyRunOnce: true,
        };

        let idNumber = 1;

        counts.forEach((count) => {
            const id = `${idSuffix}-${idNumber}`;
            let value = parseFloat(count.innerHTML);

            let optionsFromDataAttr = {...count.dataset};

            for (const key in optionsFromDataAttr) {
                if (optionsFromDataAttr[key] === '') {
                    optionsFromDataAttr[key] = true;
                }
            }
            if (optionsFromDataAttr.suffix === true) {
                optionsFromDataAttr.suffix = ''
            }
            count.id = id;

            if (isNaN(value)) {
                value = 0;
                document.getElementById(id).parentNode.classList.add('count-down');
            }

            let counter = new CountUp(
                id,
                value,
                Object.assign(Object.assign({}, defaultOptions), optionsFromDataAttr)
            );
            idNumber++;

            let options = {
                root: document,
                rootMargin: "0px",
                threshold: 1.0,
            };
            let cb = (entries, observer) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        counter.start()
                    }
                });
            };
            let observer = new IntersectionObserver(cb, options);
            observer.observe(count);


        });

    }
}
countUp();

