import Swiper, {
  Autoplay,
  EffectCreative,
  EffectFade,
  Navigation,
  Controller,
} from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';

let workSwiperWr = document.querySelectorAll('.step-swiper-wr');
workSwiperWr.forEach((el) => {
  if (el) {
    let cardsSwiperEl = el.querySelector('.step-swiper-cards');
    let textSwiperEl = el.querySelector('.step-swiper-text');
    let nextEl = el.querySelector('.swiper-button-next');
    let prevEl = el.querySelector('.swiper-button-prev');
    let slidesCount = el.querySelectorAll('.swiper-slide').length;
    let cardsSwiper = new Swiper(cardsSwiperEl, {
      modules: [Autoplay, Navigation, EffectCreative, Controller],
      spaceBetween: 20,
      slidesPerView: 1,
      speed: 800,
      threshold: 10,
      grabCursor: true,
      limitProgress: 2,
      effect: 'creative',
      creativeEffect: {
        limitProgress: slidesCount,
        prev: {
          translate: ['-50%', 0, 100],
          scale: 0,
          opacity: 0,
        },
        next: {
          translate: [20, 0, 0],
        },
      },
      navigation: {
        nextEl: nextEl,
        prevEl: prevEl,
      },
      breakpoints: {

        1100: {
          creativeEffect: {
            limitProgress: slidesCount,
            prev: {
              translate: ['-50%', 0, 100],
              scale: 0,
              opacity: 0,
            },
            next: {
              translate: [20, 0, 0],
            },
          },
        },
      },

      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
        pauseOnMouseEnter: false,
      },
    });

    let textSwiper = new Swiper(textSwiperEl, {
      modules: [EffectFade, Controller],
      spaceBetween: 20,
      slidesPerView: 1,
      speed: 800,
      threshold: 10,
      effect: 'fade',
      autoHeight: true,

      fadeEffect: {
        crossFade: true,
      },
    });

    swiperObserver(cardsSwiper);

    cardsSwiper.controller.control = textSwiper;
    textSwiper.controller.control = cardsSwiper;
  }
});
