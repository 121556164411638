import './utils/vh.utils.js';
import './utils/animate-on-view.utils';
import './libs/countUp.lib.js';

/*swipers*/
import './components/logos-swiper.component.js';
import './components/promises-swiper.component.js';
import './components/ts-swiper.component.js';
import './components/work-swiper.component.js';
import './components/work-area-swiper.component.js';
import './components/step-swiper.component.js';
import './components/dna-swiper.component.js';
import './components/team-thumbnails-swiper.component.js';
/*end of swipers*/

import './components/header.component.js';
import './components/uploadFile.component.js';
import './components/inputs.component.js';
import './components/sector-tabs.component.js';
import './components/quiz.component.js';
import './components/plyr-video.component.js';
import './components/try-card-popups.component.js';
import './components/get-in-touch-popup.component.js';
import './components/aos.component.js';
import './components/team-popup.component.js';
import './components/meta-select.component.js';
import './components/commission-form.component.js';
import './components/partners-calculator.component.js';


