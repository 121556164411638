import Swiper, { Autoplay, Navigation} from 'swiper';
import { swiperOnResize } from '../utils/swiperOnResize.js';


let dnaSwiperWr = document.querySelectorAll('.dna-swiper-wr');
dnaSwiperWr.forEach((el) => {
  if (el) {
    let swiperEl = el.querySelector('.swiper');
    let nextEl = el.querySelector('.swiper-button-next');
    let prevEl = el.querySelector('.swiper-button-prev');
    let slidesCount = el.querySelectorAll('.swiper-slide').length;
     let wrapper = swiperEl.querySelector('.swiper-wrapper');
    swiperOnResize('(max-width: 850px)', swiperEl,
        {
          modules: [Autoplay, Navigation],
          slidesPerView: 'auto',
          spaceBetween: 25,
          speed: 1000,
          autoplay: {
            delay: 5000,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
          },
          navigation: {
            prevEl: prevEl,
            nextEl: nextEl,
          },
        },
    );
  }
});

