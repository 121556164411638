document.addEventListener('DOMContentLoaded', function(){
    let sectorButtons = [...document.getElementsByClassName('sectors-switchers-item')];
    let sectorTabs = [...document.getElementsByClassName('sectors-info-tabs-item')];
    let currentIdx = 0;
    let infoTabsList = document.querySelector('.sectors-info-tabs');
    let prevSectorButton = document.querySelector('.sectors-switchers-item-prev');
    let nextSectorButton = document.querySelector('.sectors-switchers-item-next');
    let autoplay;
    let autoplayDelay = 5000;
    if (sectorButtons.length) {

        if(sectorTabs.length < 2){
            prevSectorButton.classList.add('hidden');
            nextSectorButton.classList.add('hidden');
        }else{
            prevSectorButton.classList.remove('hidden');
            nextSectorButton.classList.remove('hidden');
        }



        function slideToTab(){
            if (window.innerWidth <=650) {
                let padding = document.querySelector('.header').clientHeight + 10;
                const y = infoTabsList.getBoundingClientRect().top + window.scrollY - padding;
                window.scroll({
                    top: y,
                    behavior: 'smooth'
                });
            }
        }

        function setCurrentTab(currentIdx) {
            sectorTabs.forEach((el, idx) => currentIdx === idx ? el.classList.add('active') : el.classList.remove('active'))
            sectorButtons.forEach((el, idx) => currentIdx === idx ? el.classList.add('active') : el.classList.remove('active'))
            slideToTab();
        }


       /* window.setSameHeight('.sectors-info-tabs-item')*/
        function nextTab() {
            ++currentIdx;
            if (currentIdx >= sectorButtons.length) {
                currentIdx = 0
            }
            setCurrentTab(currentIdx)
        }

        function prevTab() {
            --currentIdx;
            if (currentIdx < 0) {
                currentIdx = sectorButtons.length - 1
            }
            setCurrentTab(currentIdx)
        }

        /*  function resetAutoplay(){
              clearInterval(autoplay);
              autoplay = setInterval(nextTab, 2000);
          }*/

        sectorButtons.forEach((button, idx) => {
            button.addEventListener('click', function () {
                currentIdx = idx;
                setCurrentTab(idx);
            })
        })

        prevSectorButton.addEventListener('click', function(){
            prevTab();
            slideToTab();
            /*  resetAutoplay(); */
        })
        nextSectorButton.addEventListener('click', function(){
            nextTab();
            slideToTab();
            /* resetAutoplay();*/

        })

        /* autoplay = setInterval(nextTab, autoplayDelay)*/

    }


})