import Swiper, { Autoplay, Navigation, Scrollbar, Mousewheel, EffectCreative} from 'swiper';
import { swiperOnResize } from '../utils/swiperOnResize.js';
import {swiperObserver} from "../utils/swiper.utils.js";


let teamThumbnailsSwiperWr = document.querySelectorAll('.team-thumbnails-swiper-wr');
teamThumbnailsSwiperWr.forEach((el) => {
  if (el) {
    let swiperEl = el.querySelector('.swiper');
    let nextEl = el.querySelector('.swiper-button-next');
    let prevEl = el.querySelector('.swiper-button-prev');
    let scrollBar = el.querySelector('.swiper-scrollbar');
    let slides = el.querySelectorAll('.swiper-slide');
    let slidesCount = el.querySelectorAll('.swiper-slide').length;
     let wrapper = swiperEl.querySelector('.swiper-wrapper');
      let swiper = new Swiper(swiperEl, {
          modules: [Autoplay, Navigation, Scrollbar, Mousewheel, EffectCreative],
          observer: true, //needs to calculate swiper size
          observeParents: true, //needs to calculate swiper size
          slidesPerView: 'auto',
          spaceBetween: 0,
          speed: 800,
          threshold: 10,
          effect: 'creative',
          centeredSlides: true,
          initialSlide: Math.floor(slidesCount / 2),

          creativeEffect:{
              limitProgress: slidesCount,
              prev: {
                  // will set `translateZ(-400px)` on previous slides
                  translate: ['-55%', 0, 0],
              },
              next: {
                  // will set `translateX(100%)` on next slides
                  translate: ['55%', 0, 0],
              },
          },
          navigation: {
              nextEl: nextEl,
              prevEl: prevEl,
          },
          scrollbar: {
              el: scrollBar,
              draggable: true
          },
          mousewheel:{
              enabled: true,
          },
          autoplay: {
            delay: 5000,
            disableOnInteraction: false,
            pauseOnMouseEnter: false,
          },
      });

      swiperObserver(swiper);
  }
});

