let commissionForms = document.querySelectorAll('.partner-form');
if (commissionForms) {
    /*commissionForms.forEach((form) => {
        let calculateButton = form.querySelector('[data-calculate]');
        let resetButton = form.querySelector('[data-reset]');
        let amountInput = form.querySelector('[data-amount]');
        let billedInput = form.querySelector('[data-billed]');
        let percentSelect = form.querySelector('[data-percent]');
        let percentTotalInput = form.querySelector('[data-percent-total]');
        let sumInput = form.querySelector('[data-sum-total]');
        let percentField = form.querySelector('.footer-percent');
        let sumField = form.querySelector('.footer-sum');

        amountInput.addEventListener('input', function (e) {

            if (this.value.length === this.maxLength) {
                this.classList.add('error');
                setTimeout(function () {
                    amountInput.classList.remove('error')
                }, 1500)

            } else {
                this.classList.remove('error')
            }
            calculate();
        })


        resetButton.addEventListener('click', function () {
            amountInput.value = '';
            percentSelect.value = '#';
            percentTotalInput.value = '';
            sumInput.value = '';
            percentField.textContent = '0';
            sumField.textContent = 0;
            resetChoice(percentSelect);
        });

        /!*calculateButton.addEventListener('click', calculate);*!/

        billedInput.addEventListener('input', function (e) {

            if (this.value.length === this.maxLength) {
                this.classList.add('error');
                setTimeout(function () {
                    billedInput.classList.remove('error')
                }, 1500)

            } else {
                this.classList.remove('error')
            }
            calculate();
        })

        function calculate() {
            let percent = percentSelect.value === '' ||  percentSelect.value === '#' ? 70 : percentSelect.value;
            let sum = ((amountInput.value / 100) * percent).toFixed(0);//was 2

            percentTotalInput.value = percent + '%';
            sumInput.value = sum + '$';
            percentField.textContent = percent;
            sumField.textContent = sum;
        }
    });*/

    commissionForms.forEach(form=>{
        let billedInput = form.querySelector('[data-billed]');
        let billed70 = form.querySelector('[data-billed-70]');
        let billed75 = form.querySelector('[data-billed-75]');
        let billed80 = form.querySelector('[data-billed-80]');
        let currency = billedInput.dataset.symbol;

        billedInput.addEventListener('input', function (e) {


            if (this.value.length === this.maxLength) {
                this.classList.add('error');
                setTimeout(function () {
                    billedInput.classList.remove('error')
                }, 1500)

            } else {
                this.classList.remove('error')
            }
            calculateBilled();
            let currentVale =  parseFloat(this.value);
            if(isNaN(currentVale)){
                this.value = currency;
            }else{
                this.value = currency + String(Math.round(currentVale)).replace(/(.)(?=(\d{3})+$)/g,'$1,')
            }


        })

        function calculateBilled() {
            let val = parseFloat(billedInput.value);


            if(typeof val === 'number' && !isNaN(val)){
                billed70.innerText = currency + String(Math.round(val*0.70)).replace(/(.)(?=(\d{3})+$)/g,'$1,');
                billed75.innerText = currency + String(Math.round(val*0.75)).replace(/(.)(?=(\d{3})+$)/g,'$1,');
                billed80.innerText = currency + String(Math.round(val*0.80)).replace(/(.)(?=(\d{3})+$)/g,'$1,');
            }else{
                billed70.innerText =  currency + '0';
                billed75.innerText =  currency + '0';
                billed80.innerText =  currency + '0';
            }
        }
    })
}

