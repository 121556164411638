let videoPopup = document.querySelector('.try-card-popup');
let plyrVideos = document.querySelectorAll('.plyr-item-popup');
let commissionPopup = document.querySelector('.commission-popup');

if (plyrVideos) {
  plyrVideos.forEach((video) => {
    window.popupPlyr = new Plyr(video, {
      controls: [
        'play-large',
        'play',
        'progress',
        'current-time',
        'mute',
        'volume',
        'fullscreen',

        'airplay',
      ],
      tooltips: { controls: !0 },
      fullscreen: { iosNative: true },
    });
    popupPlyr.on('play', function () {
      this.classList.add('play-start');
    });
  });
}

if (videoPopup) {
  window.closeVideoPopup = function closeVideoPopup() {
    videoPopup.classList.remove('active');
    scrollLock.enablePageScroll();
    popupPlyr.pause();
  };
  window.openVideoPopup = function closeVideoPopup() {
    videoPopup.classList.add('active');
    scrollLock.disablePageScroll();
  };
}

if (commissionPopup) {
  window.closeCommissionPopup = function closeVideoPopup() {
    commissionPopup.classList.remove('active');
    scrollLock.enablePageScroll();
    popupPlyr.pause();
  };
  window.openCommissionPopup = function openCommissionPopup() {
    let inner = commissionPopup.querySelector('.commission-popup-inner');
    let scrollSelector = inner.querySelector('.simplebar-content-wrapper');
    commissionPopup.classList.add('active');
    scrollLock.disablePageScroll(inner);
    scrollLock.addScrollableSelector(scrollSelector);
  };
}
